import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The hardest part is getting started: How to efficiently use a screen reader
and which keyboard shortcuts and gestures will make your life easier.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIFAwT/xAAVAQEBAAAAAAAAAAAAAAAAAAACAf/aAAwDAQACEAMQAAAB5ckpBRxxT//EABsQAQACAgMAAAAAAAAAAAAAAAEDEQACISIx/9oACAEBAAEFAtKpLxeT1O0oEn//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAABAgcAAAAAAAAAAAAAAAABABECAxAhUWFx/9oACAEBAAY/ArhzisPVMGkWX//EABoQAQADAQEBAAAAAAAAAAAAAAEAESFhMVH/2gAIAQEAAT8ht6HpiC/agiy4kQ+IGDKMNxRc/9oADAMBAAIAAwAAABAY3//EABYRAQEBAAAAAAAAAAAAAAAAAAEQEf/aAAgBAwEBPxANn//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxAiTQ3/xAAbEAACAwEBAQAAAAAAAAAAAAABEQAhMVFhof/aAAgBAQABPxAzyroRQwL7QyJlgV8vkADodFRJAOy9mHDVFWzfyLOBoOf/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Settings menu on the iPhone for the screen reader VoiceOver",
          "title": "Settings menu on the iPhone for the screen reader VoiceOver",
          "src": "/static/d008de0849a9b080db26f42cdaf6150c/e5166/iphone-voiceover-settings.jpg",
          "srcSet": ["/static/d008de0849a9b080db26f42cdaf6150c/f93b5/iphone-voiceover-settings.jpg 300w", "/static/d008de0849a9b080db26f42cdaf6150c/b4294/iphone-voiceover-settings.jpg 600w", "/static/d008de0849a9b080db26f42cdaf6150c/e5166/iphone-voiceover-settings.jpg 1200w", "/static/d008de0849a9b080db26f42cdaf6150c/b17f8/iphone-voiceover-settings.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Alexander Lehner`}</em></p>
    <h2>{`My First Time`}</h2>
    <p>{`Three years ago, when I activated a screen reader on my laptop for the first time, I briefly panicked.
A robotic voice began talking to me at high speed. Intuitively I tried to match what I heard
to what I saw on the screen – and failed miserably. Very stressful!`}</p>
    <p>{`Using a screen reader can be confusing at first, especially for sighted people. For example, for
web developers who want to check a newly implemented feature for accessibility. Or for testers
who want to audit an application for the blind and visually impaired.
So I want to share a few tips to make it easier to get started.`}</p>
    <h2>{`Tips for Beginners`}</h2>
    <p>{`For desktop devices running Windows, I can recommend the free screen reader `}<a parentName="p" {...{
        "href": "https://www.nvaccess.org/"
      }}>{`NVDA`}</a>{`.
The screen reader `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/VoiceOver"
      }}>{`VoiceOver`}</a>{` is already pre-installed on Apple devices like the
iPhone. The counterpart on Android devices is called `}<a parentName="p" {...{
        "href": "https://support.google.com/accessibility/android/answer/6283677?hl=en"
      }}>{`TalkBack`}</a>{`.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Where's the stop button?`}</strong>{` Every screen reader provides a way to pause reading.
For example, with VoiceOver, simply tap anywhere on the screen with two fingers.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Not so fast!`}</strong>{` Experienced users have their screen reader read text at double or even higher speed.
Especially at the beginning you should slow down the reading speed and adapt it to your individual needs.
With NVDA, for example, you can set the speed with a slider in the settings under “Speech”.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Choose the right voice`}</strong>{`: In the screen reader settings, select the voice you find most agreeable.
Most of the time there are several male and female voices to choose from.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`Gestures and Keyboard Shortcuts`}</strong>{`: Screen readers offer a variety of gestures and keyboard shortcuts
that you can use to perform specific actions more quickly. For example, if you swipe left or right with three
fingers when TalkBack is activated, you can select a navigation mode (e.g. headings). Then swipe up or down
with one finger to jump to the previous or next item of that type. This way you can quickly check whether
elements have been implemented with the correct semantic markup.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><strong parentName="p">{`At your command!`}</strong>{` Find out the quickest way to turn the screen reader on and off. By default,
NVDA can be started with the key combination CTRL + ALT + N and switched off with INS + Q. On the iPhone,
you must explicitly set VoiceOver as a shortcut in the settings under “Accessibility”. Then you can toggle
the screen reader on and off by triple-pressing the home button.`}</p>
      </li>
    </ol>
    <p>{`The most important thing is to give yourself the time you need. Always remember: practice makes perfect.`}</p>
    <h2>{`Useful Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.nvaccess.org/files/nvda/documentation/userGuide.html"
        }}>{`NVDA User Guide`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dequeuniversity.com/screenreaders/nvda-keyboard-shortcuts"
        }}>{`NVDA Keyboard Shortcuts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.apple.com/guide/iphone/learn-voiceover-gestures-iph3e2e2281/ios"
        }}>{`VoiceOver Gestures`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://support.google.com/accessibility/android/answer/6151827?hl=en"
        }}>{`TalkBack Gestures`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      